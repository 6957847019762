@import "../../utils/_mixins.scss";

.container {
  position: relative;
  height: 25rem;
  width: 100%;
  text-align: center;
  font-size: 1.3rem;

  img {
    margin: 2.5rem auto;
  }

  .lottieContainer {
    max-width: 100%;
    display: flex;
    justify-content: space-evenly;

    .item {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      height: 10rem;
      // max-width: 30%;

      .lottie {
        height: 100%;
      }

      .title {
        font-weight: 400;
        font-size: 1.3rem;
        width: 60%;
        margin-bottom: unset;
      }
    }
  }

  .btn {
    position: absolute;
    bottom: -8rem;
    transform: translateX(-50%);
    left: 50%;
  }
}

@media screen and (max-width: 992px) {
  .container {
    height: 30rem;
    img {
      margin: 1.5rem auto;
      width: 100%;
    }
    .lottieContainer {
      max-width: 100%;
      flex-direction: column;

      .item {
        gap: 1rem;

        .title {
          font-size: 1rem;
          width: 70%;
          margin-bottom: 1rem;
        }
      }
      & > :nth-child(2) {
        height: 11rem;
        margin-top: 2.5rem;
        gap: 0;
      }
    }

    @include not-last-child(".item") {
      border-bottom: 0.5px solid #ffffff;
    }

    .btn {
      bottom: -7rem;
    }
  }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    height: 50vh;

    .lottieContainer {
      flex-direction: row;

      .item {
        width: 50%;
        height: 40%;
      }
      & > :nth-child(2) {
        height: unset;
        margin-top: unset;
      }

      .item {
        height: 4rem;
      }

      @include not-last-child(".item") {
        border-right: 0.5px solid #ffffff;
        border-bottom: unset;
      }
    }
    .btn {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 389px) {
  .container {
    .btn {
      bottom: -6.5em;
    }
  }
}
