:root {
  --colorWhite: #fff;
  --colorBlack: #000;
  --secondaryColor: #ff4651;
  --textColor1: #07c7ff;
  --primaryColor: linear-gradient(
    180deg,
    rgba(28, 111, 148, 0.85) 0%,
    rgba(43, 70, 82, 0.85) 100%
  );
  --mainBackgroundColor: linear-gradient(
      0deg,
      rgba(12, 12, 12, 0.83),
      rgba(12, 12, 12, 0.83)
    ),
    linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );

  /* --app-height: calc(100vh - calc(100vh - 100%)); */
  --app-height: 100vh;
  /* --app-height: calc(100vh - 120px); */
}
