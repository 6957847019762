.container {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  gap: 1rem;
  color: #fff;
  font-weight: 400;
  font-size: 1.2rem;
  max-width: 30rem;
  background: rgba(34, 34, 34, 0.7);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(17px);
  border-radius: 10px;
  padding: 1.5rem 2rem;
  min-width: 20rem;
  bottom: 8%;
  transition: all 0.6s ease-in-out;

  img{
    width: 3rem;
  }

  .closeBtn {
    position: absolute;
    right: 5%;
    top: 10%;
    cursor: pointer;
  }
}

@media screen and (max-width: 992px) {
  .container {
    width: 90%;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    font-size: 1rem;
  }
}
