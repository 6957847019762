.container {
  position: absolute;
  bottom: 4rem;
  left: 2.5rem;
  right: 2.5rem;

  .slider {
    display: flex;

    .label,
    .customLabel {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .label {
      background: #d9d9d9;
      border-radius: 5.875px;
      color: #000;
      font-size: 1.3rem;
      letter-spacing: 0.235px;
      margin-right: 1rem;
      padding: 0.5rem;
      min-width: 12rem;
    }
    .customLabel {
      gap: 1rem;
    }
  }
}
