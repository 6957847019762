.container {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  background: rgba(26, 26, 26, 0.85);
  backdrop-filter: blur(8.53448px);
  padding-top: 5rem;
  z-index: 20;
  flex-direction: column;

  align-items: flex-start;
  overflow: hidden;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    padding: 0 1.5rem;
    min-height: 4rem;
    width: 100%;
    z-index: 1;
    background: #1a1a1a;
    backdrop-filter: blur(8.53448px);
    font-weight: 400;
    font-size: 1.3rem;

    .closeIcon {
      cursor: pointer;
    }
  }

  .body {
    width: 100%;
    overflow: auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;

    .sidebarItem {
      padding: 1rem;
      padding-bottom: 2rem;
      position: relative;
      background: #2f2f2f;
      border-radius: 14.1994px;
      font-size: 1.1rem;
      margin-bottom: 1rem;
      align-self: flex-start;
      width: 80%;

      .name {
        color: #cfeb27;
        margin-bottom: 0.5rem;
      }

      .questionContainer {
        padding: 2rem 0;
        width: 90%;

        .questionTitle {
          font-weight: 600;
        }
      }
      .replyContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
        gap: 1rem;
      }
      .btnContainer {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .btn {
          margin-top: 0;
          font-size: 0.9rem;
          font-weight: 400;
          background-color: var(--colorWhite);
          color: var(--colorBlack);
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.45);
          height: 3rem;

          &:hover {
            background-color: var(--colorBlack);
            color: var(--colorWhite);
          }
        }
      }

      &::before {
        position: absolute;
        content: "";
        border-style: solid;
        border-width: 10px 15px 10px 0;
        border-color: transparent #2f2f2f transparent transparent;
        position: absolute;
        left: -0.9rem;
        bottom: 1rem;
      }

      .time {
        font-size: 0.95rem;
        position: absolute;
        right: 5%;
        bottom: 2%;
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .sidebarReply {
      align-self: flex-end;
      background: #576f79;
      border-radius: 14.1994px;

      .name {
        color: #27ebdf;
      }

      &::before {
        border-color: transparent #576f79 transparent transparent;
        right: -0.9rem;
        left: unset;
        transform: rotate(180deg);
      }
    }
   

    /* Track */
    &::-webkit-scrollbar-track {
      background: #c3c3c39e;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fff; 
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }

    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
  }
}

@media screen and (min-width: 992px) {
  .container {
    bottom: 5%;
    right: 6%;
    top: unset;
    left: unset;
    width: 29rem;
    min-height: 36rem;
    max-height: 36rem;
    padding: 1.5rem;
    padding-top: 5rem;
    padding-bottom: 3rem;
    border-radius: 2rem;
  }
}
