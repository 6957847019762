.container {
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(12px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--app-height);
  padding: 1rem;
  padding-bottom: 4rem;
  overflow: hidden;
  overflow-y: auto;

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .title {
    font-size: 1.5rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;

    .closeBtn {
      cursor: pointer;
    }
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;

    .left {
      position: relative;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }

      .tickerImg {
        position: absolute;
        top: 0;
      }
    }
    .right {
      .scrollbar {
        display: flex;
        gap: 1rem;
        overflow: auto;
        margin: 1rem 0;
        padding: 1rem 0;
        margin-left: 1rem;

        /* Hide scrollbar for IE, Edge and Firefox */

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .item {
          font-size: 1rem;
          min-width: 8rem;
          max-width: 10rem;
          padding: 0.8rem;
          background: rgba(0, 0, 0, 0.5);
          border: 1px solid #ff4651;
          background-color: transparent;
          color: #fff;
          backdrop-filter: blur(17px);

          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          /* Note: backdrop-filter has minimal browser support */
          border-radius: 2.5rem;
          transition: all 0.1s ease-in-out;

          &:hover {
            background-color: #fff;
            color: #000;
          }
        }

        .active {
          background-color: #fff;
          color: #000;
        }

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .details {
        background: rgba(0, 0, 0, 0.45);
        backdrop-filter: blur(12px);
        display: flex;
        padding: 1rem;
        gap: 1.3rem;
        flex-direction: column;

        .live {
          display: flex;
          justify-content: space-between;

          .liveContainer {
            display: flex;
            align-items: center;
            padding: 0.4rem;
            border: 1px solid #ffffff;
            font-size: 0.9rem;
            border-radius: 0.5625rem;
            font-weight: 400;

            .liveIndicator {
              height: 0.625rem;
              width: 0.625rem;
              border-radius: 50%;
              background: #ff4651;
              margin-right: 0.3rem;
            }
          }
          .title {
            font-size: 1.6rem;
            font-weight: 500;
          }
        }
        .description {
          font-size: 1rem;
          opacity: 0.4;
          font-weight: 400;
        }

        button {
          height: 2.5rem;
          align-self: center;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .container {
    padding: 1rem;
    padding-bottom: unset;
    .detailsContainer {
      flex-direction: row;
      justify-content: unset;
      align-items: center;
      margin-top: -5rem;

      .left {
        width: 63.625rem;
        align-self: center;
        margin-top: 15rem;
      }

      .right {
        width: 50%;
        max-width: 50%;
        min-height: 40%;

        .scrollbar {
          padding-left: 0;
          margin-left: 0;

          .item {
            font-size: 1.1rem;
            min-width: 9rem;
            max-width: 11rem;
          }
        }
        .details {
          max-width: 85%;
          padding-left: 0;
          background: unset;
          backdrop-filter: unset;

          .live {
            .liveContainer {
              font-size: 1rem;

              .liveIndicator {
                height: 0.7rem;
                width: 0.7rem;
              }
            }
          }
          .description {
            font-size: 1.3rem;
          }
          button {
            align-self: flex-start;
          }
        }
      }
    }
    .title {
      font-size: 2.2rem !important;
    }
  }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    .detailsContainer {
      overflow: auto;

      .left {
        padding-top: 33rem;
        .tickerImg {
          position: absolute;
          object-fit: contain;
          top: 18rem;
        }
      }

      .right {
        padding-bottom: 3rem;
      }
    }
  }
}

@media screen and (max-width: 389px) and (orientation: landscape) {
  .container {
    .detailsContainer {
      .left {
        padding-top: 30rem;
        .tickerImg {
          top: 15rem;
        }
      }
    }
  }
}
