@import "../../utils/_mixins.scss";

@include keyframes(shake) {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(12deg);
  }
  100% {
    transform: rotate(0);
  }
}

@include keyframes(emoteFadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  overflow: hidden;
  width: 100%;

  .topBtnContainer {
    position: fixed;
    top: 2%;
    right: 5%;

    .left,
    .right {
      display: flex;
    }

    .left {
    }
    .right {
      @include not-last-child(".imgContainer") {
        margin-right: 1rem;
      }
    }
  }

  .bottomBtnContainer {
    position: fixed;
    bottom: 2%;
    right: 5%;
    display: flex;
    justify-content: space-between;

    @include not-last-child(".imgContainer") {
      margin-bottom: 1rem;
    }
  }
  .imgContainer {
    cursor: pointer;
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.57382px solid rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(11.0167px);
    border-radius: 50%;

    .emoteContainer {
      position: relative;

      .emoteList {
        position: absolute;
        background: rgba(34, 34, 34, 0.7);
        border: 1.65306px solid rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(11.5714px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 96.53px;
        width: 3.6rem;
        left: 50%;
        transform: translateX(-50%);
        flex-direction: column;
        align-items: center;
        padding-top: 2.5rem;
        bottom: 0;
        padding-bottom: 5rem;
        transform-origin: bottom;
        z-index: -1;
        @include animation("emoteFadeIn 0.4s cubic-bezier(.36,.07,.57,.99)");

        .item {
          width: 2rem;
        }
        @include not-last-child(".item") {
          margin-bottom: 2rem;
        }
      }

      .emoteClose {
        height: 3.6rem;
        width: 3.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
  .sidebarHeader,
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    padding: 0 1.5rem;
    min-height: 4rem;
    width: 100%;
    z-index: 1;
  }
  .sidebarHeader {
    background: rgba(26, 26, 26, 0.85);
    backdrop-filter: blur(7px);
    font-weight: 400;
    font-size: 1rem;

    .closeIcon {
      cursor: pointer;
    }
  }
  .headerContainer {
    top: 4rem;
    justify-content: center;
    gap: 3rem;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    background: inherit;

    p {
      line-height: 2.2rem;
    }
    .headerItem {
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 992px) {
  .topBtnContainer {
    right: 2.5rem !important;
  }
  .bottomBtnContainer {
    bottom: 3.5rem !important;
    right: 2.5rem !important;
  }
  .imgContainer {
    .emoteContainer {
      .emoteList {
        padding-top: 3rem;
        padding-bottom: 5.5rem;

        @include not-last-child(".item") {
          margin-bottom: 3rem;
        }
      }
    }
  }
  .bottomBtnContainer,
  .topBtnContainer {
    .shakeAnimation {
      &:hover {
        @include animation("shake 1s cubic-bezier(.36,.07,.57,.99) infinite");
      }
    }
  }
}

@media screen and (orientation: landscape) {
  .container {
    .sidebarHeader,
    .headerContainer {
      min-height: 3rem;
    }

    .headerContainer {
      top: 3rem;
    }
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    .imgContainer {
      .emoteContainer {
        .emoteList {
          flex-direction: row;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 2rem;
          height: 3.6rem;
          right: 0;
          left: unset;
          transform: translateX(0);
          transform-origin: right;
          align-items: center;
          width: 18rem;

          @include not-last-child(".item") {
            margin-bottom: 0;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
