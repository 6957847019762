.not-card-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-card-btn-container .poll-btn {
  margin-right: 0.45rem;
  padding: 0.85rem 1rem;
}

.not-time-container {
  display: flex;
  justify-content: flex-end;
  color: #a9a9a9;
  margin: 0;
}

.not-time-container>p{
  font-size:1rem;
  text-align: right;
}

.not-time-container.admin {
  flex-direction: column;
}

.poll-form-container {
  padding-bottom: 1.5rem;
}
.notyHeader{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.notytitle{
  font-size: 1.3rem;
}
.notycls{
  cursor: pointer;
}
