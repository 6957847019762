.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.85);
  backdrop-filter: blur(7px);
  z-index: 5;
  padding: 1.5rem;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .container {
    top: unset;
    bottom: 3.5rem;
    left: unset;
    right: 7rem;
    width: 25rem;
    padding-bottom: 3rem;
    border-radius: 2rem;
    overflow: hidden;
    min-height: 40rem;
  }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: unset;
    min-height: unset;
  }
}
