.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;

  button {
    position: relative;
    overflow: hidden;

    span {
      position: relative;
      z-index: 2;
    }
  }
  .loader {
    width: 100%;
    height: 0%;
    background-color: var(--secondaryColor);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1,
    h4 {
      font-weight: 600;
    }

    p {
      font-weight: 400;
    }

    h1,
    h4,
    p,
    .privacyContainer {
      align-self: flex-start;
    }
    h1 {
      margin-bottom: 0.6rem;
    }
    h4 {
      margin-bottom: 1rem;
      letter-spacing: 1px;
    }

    .privacyContainer {
      display: flex;
      gap: 0.1rem;
      font-weight: 500;
      margin-top: 2rem;
      line-height: 1;

      a {
        color: var(--colorWhite);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .container {
    flex-direction: row;
    width: 50rem;
    font-size: 1.3rem;

    .imgContainer {
      width: 50%;
    }

    .detailsContainer {
      gap: 0.5rem;
      width: 50%;
      align-items: flex-start;
    }
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    flex-direction: row;
    width: 35rem;

    .imgContainer {
      width: 40%;
    }

    .detailsContainer {
      width: 50%;
      align-items: flex-start;
    }
  }
}
