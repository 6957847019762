.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--app-height);
  padding: 0 1rem;
  background-image: url(../../assets/images/intialBG.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  .videoContainer {
    position: relative;
    height: unset;
    width: 60rem;
    background: #000;
    border-radius: 0.5rem;
    aspect-ratio: 16/9;
    overflow: hidden;

    .video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  .loadingContainer {
    position: absolute;
    bottom: 7rem;
    display: flex;
    width: 90%;
    justify-content: center;
    gap: 0.8rem;
    flex-direction: column;
    transform: translateX(-50%);
    left: 50%;
    font-size: 0.9rem;
    margin-top: 0;
  }
}

@media screen and (min-width: 992px) {
  .container {
    .loadingContainer {
      font-size: 1rem;
      max-width: 55rem;
    }
  }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  .container {
    align-items: flex-start;
    padding-top: 2rem;
    .videoContainer {
      width: 25rem;
    }

    .loadingContainer {
      bottom: 2rem;
    }
  }
}
