.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;

  .backBtn {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .userName {
    color: var(--textColor1);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
  }

  canvas {
    background-color: rgba(255, 255, 255, 0.5);
  }
  form {
    width: 100%;
  }
  .btn {
    transform: translateX(-50%);
    position: relative;
    left: 50%;
  }
  .categoryContainer {
    width: 100%;

    .avatarCategory,
    .avatarCategoryItems,
    .avatarCategoryItemsColor {
      display: flex;
      justify-content: space-between;
    }

    .avatarCategory {
      .item {
        cursor: pointer;
        border-radius: 50%;
        height: 3.5rem;
        width: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
      }
    }
    .avatarCategoryItems {
      flex-wrap: wrap;

      .item {
        cursor: pointer;
        border-radius: 50%;
        height: 5rem;
        width: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border: 3px solid transparent;
        background: var(--colorWhite);
        margin-top: 1rem;
      }
    }
    .avatarCategoryItemsColor {
      flex-wrap: wrap;
      // gap: 1.5rem;

      .item {
        cursor: pointer;
        border-radius: 50%;
        height: 3.5rem;
        width: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border: 3px solid transparent;
        background: var(--colorWhite);
        margin-top: 1rem;
      }
    }
  }

  .btn-container {
    background: linear-gradient(
      180deg,
      rgba(15, 80, 109, 0.85) 0%,
      rgba(43, 70, 82, 0.85) 100%
    );
    backdrop-filter: blur(17px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 25px;
    label {
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
    }
    .switch {
      position: relative;
      .switch-inner {
        margin: 0;
        width: 14rem;
        height: 3rem;
        border-radius: 26px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;
        display: block;

        &:before,
        &:after {
          position: absolute;
          font-weight: 700;
          font-size: 1rem;
          letter-spacing: 0.25px;
        }

        &:before {
          content: attr(data-on);
          top: 0.8rem;
          right: 2rem;
          opacity: 0.6;
        }
        &:after {
          content: attr(data-off);
          width: 7rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          background: linear-gradient(
            180deg,
            rgba(1, 14, 19, 0.85) 0%,
            rgba(43, 70, 82, 0.85) 100%
          );
          backdrop-filter: blur(17px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: inherit;
          color: var(--secondaryColor);
          border-bottom: 1px solid var(--secondaryColor);
        }
      }
      input[type="checkbox"] {
        cursor: pointer;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;

        &:checked + .switch-inner {
          &::after {
            content: attr(data-on);
            left: 7rem;
            background: linear-gradient(
              180deg,
              rgba(1, 14, 19, 0.85) 0%,
              rgba(43, 70, 82, 0.85) 100%
            );
            backdrop-filter: blur(17px);
            border-radius: inherit;
          }
          &::before {
            content: attr(data-off);
            left: 2.5rem;
            top: 0.8rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .container {
  }
}
