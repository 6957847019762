.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  background-image: url("../../assets/images/intialBG.jpg");
  background-size: cover;
  position: fixed;
  height: var(--app-height);
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 3rem;

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .container {
    background: var(--primaryColor);
    // backdrop-filter: blur(34px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 25px;
    padding: 2rem;

    .rights {
      text-align: center;
      opacity: 0.5;
      font-size: 0.8rem;
      width: 100%;
      margin-top: 1rem;
    }
  }

  .bottomTagLine {
    position: fixed;
    bottom: 1rem;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    font-size: 0.8rem;
  }
}

.canvas {
  width: 100%;
  height: 100%;
}

.canvasLoading {
  all: unset;

  .container {
    all: unset;
  }
}

.createAvatarDesktop {
  padding-bottom: 0;
}
@media screen and (min-width: 992px) {
  .mainContainer {
    padding: 0;

    .bottomTagLine {
      width: unset;
      font-size: 1rem;
      bottom: 3rem;
    }
  }

  .createAvatarDesktop {
    .container {
      position: absolute;
      right: 4%;
    }
  }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  .mainContainer {
    .container {
      transform: scale(0.8);
    }
  }
}

@media screen and (max-width: 390px) {
  .mainContainer {
    .container {
      transform: scale(0.8);
    }
  }
}
