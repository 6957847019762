#rotate {
  z-index: 199;
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  /* display: none; */
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
  /* display: none; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  #rotate {
    display: flex;
  }
}
