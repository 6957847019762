@import url("./utils/_css_variables.css");

@font-face {
  font-family: "BeVietnamPro-Regular";
  src: url(./assets/fonts/Be_Vietnam_Pro/BeVietnamPro-Regular.ttf);
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}
*,
::before,
::after {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "BeVietnamPro-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* height: calc(100vh - env(safe-area-inset-bottom)); */

  height: var(--app-height);
  background: var(--mainBackgroundColor);
  backdrop-filter: blur(24px);
  color: var(--colorWhite);
  font-weight: 400;
  overflow: hidden;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}
.d-flex {
  display: flex;
}

input,
.btn {
  outline: none;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modalOverlay,
.modalOverlayWithTint {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.modalOverlayWithTint {
  background: rgba(0, 0, 0, 0.4);
}

.modalOverlayWithBlur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: var(--primaryColor);
  backdrop-filter: blur(32px);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.btn {
  margin-top: 2.2rem;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
  background-color: var(--secondaryColor);
  color: var(--colorWhite);
  display: flex;
  height: 3.5rem;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
}

.btn:hover:not([disabled]) {
  background-color: var(--colorWhite);
  color: var(--secondaryColor);
}

button {
  cursor: pointer;
}

.btn:disabled {
  background-color: #808080;
  cursor: not-allowed;
}

.input-group {
}
.input-group label {
  color: #eeeeee;
}

.form-control {
  outline: none;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
  height: auto;
  width: 100%;
  color: #eeeeee;
  background: rgba(12, 12, 12, 0.5);
  border: 0.5px solid #ffffff;
  border-radius: 6px;
  margin: 1rem 0;
}

.form-control:focus {
  outline: none;
}

/* hide updown arrow of type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

/* error */
.error {
  color: var(--secondaryColor);
  margin-bottom: 0.5rem;
}

input:disabled {
  background-color: #ccc;
  color: #000;
  cursor: not-allowed;
}

.UILoader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}
.UILoader img {
  width: 4rem;
}

.activeItem {
  background-color: var(--secondaryColor);
}
.activeItemWithTick {
  border: 3px solid var(--secondaryColor);
}

/* custom linear loader */
.linearLoader {
  background-color: #5a5a5a;
  width: 100%;
  height: 0.5rem;
  overflow: hidden;
}

.linearLoader .percentage {
  position: relative;
  max-width: 100%;
  height: 100%;
  -webkit-transition: width 500ms ease-out 1s;
  -moz-transition: width 500ms ease-out 1s;
  -o-transition: width 500ms ease-out 1s;
  transition: width 500ms ease-out 1s;
  background-color: var(--secondaryColor);
}
/* custom checkbox start */

/* The container */
.customCheckBox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;

  border: 0.5px solid #ffffff;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.customCheckBox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a background */
.customCheckBox input:checked ~ .checkmark {
  background: #182a32;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customCheckBox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customCheckBox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--colorWhite);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* custom checkbox end */

/* custom audio slider start */
.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
  background: transparent;
}
.react-multiple-carousel__arrow--left {
  left: 1px;
}
.react-multiple-carousel__arrow--right {
  right: 1px;
}
.react-multiple-carousel__arrow--left::before,
.react-multiple-carousel__arrow--right::before {
  color: #fff;
}
/* custom audio slider end */

@media screen and (max-width: 992px) {
  body {
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
  }
}
