.container {
  position: relative;

  .icons {
    position: absolute;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
  .playPause {
    top: 50%;
    padding: 0.4rem;
    transform: translate(-50%, -50%);
    left: 50%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .fullScreen {
    top: 4%;
    right: 0;
  }
}
