@import "../../../../utils/_mixins.scss";

@include keyframes(skipContainer) {
  from {
    bottom: -15rem;
  }
  to {
    bottom: 2rem;
  }
}

.IntroTransitionVideo {
  position: fixed;
  transition: 0.15s;
  opacity: 0;
  pointer-events: none;
  height: var(--app-height);
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 40;

  .video {
    height: 100%;

    & > iframe {
      width: 100%;
      height: 100%;
      background: #000;
      overflow: hidden;
    }
  }
}

.fadeInTransitionVideo {
  opacity: 1;
}
.fadeOutTransitionVideo {
  opacity: 1;
}

.skipContainer {
  position: relative;
  width: 100%;
  height: var(--app-height);
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;
  opacity: 0.3;
  z-index: 50;

  .btn {
    position: absolute;
    margin-top: 0;
    right: 1rem;
    bottom: -15rem;
    @include animation("skipContainer 0.5s linear forwards 1s");
  }
}

.skipContainer:hover {
  opacity: 1;
}
