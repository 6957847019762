.container {
  position: relative;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  height: 100%;
  width: 100vw;

  .backBtn {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .userName {
    color: var(--textColor1);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
  }

  canvas {
    background-color: rgba(255, 255, 255, 0.5);
  }

  form {
    width: 100%;
  }

  .btn {
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    width: 8rem;
  }

  .categoryContainer {
    width: 100%;

    .avatarCategory,
    .avatarCategoryItems,
    .avatarCategoryItemsColor {
      display: flex;
      justify-content: space-between;
    }

    .avatarCategory {
      .item {
        cursor: pointer;
        border-radius: 50%;
        height: 3.5rem;
        width: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
      }
    }

    .avatarCategoryItems {
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        cursor: pointer;
        border-radius: 50%;
        height: 5rem;
        width: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 1rem;
        border: 3px solid var(--colorWhite);
        background: var(--colorWhite);
        margin-top: 1rem;
        transition: 0.25s;
        position: relative;
      }

      .activeStyles {
        border: 3px solid var(--secondaryColor);
      }

      .activeStyles::after {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        background-color: rgba(100, 100, 100, 0.66);
        background-image: url("../../../../assets/images/tick.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
      }
    }

    .avatarCategoryItems.specs {
      .activeStyles::after {
        content: " ";
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: -1.5rem;
        left: unset;
        top: 0.5rem;
        border-radius: 15%;
        background-color: var(--secondaryColor);
        background-image: url("../../../../assets/images/tick.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .avatarCategoryItemsColor {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, 1fr);

      .item {
        cursor: pointer;
        border-radius: 50%;
        height: 3.5rem;
        width: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        padding: 1rem;
        border: 3px solid transparent;
        background: var(--colorWhite);
        margin-top: 1rem;
        position: relative;
      }

      .activeStyles {
        border: 3px solid var(--secondaryColor);
      }

      .activeStyles::after {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        background-color: rgba(100, 100, 100, 0.25);
        background-image: url("../../../../assets/images/tick.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
      }
    }

    .repeat3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
    }

    .avatarCustomColor {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .btn-container {
    background: linear-gradient(
      180deg,
      rgba(15, 80, 109, 0.85) 0%,
      rgba(43, 70, 82, 0.85) 100%
    );
    backdrop-filter: blur(17px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 25px;

    label {
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
    }

    .switch {
      position: relative;

      .switch-inner {
        margin: 0;
        width: 14rem;
        height: 3rem;
        border-radius: 26px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;
        display: block;

        &:before,
        &:after {
          position: absolute;
          font-weight: 700;
          font-size: 1rem;
          letter-spacing: 0.25px;
        }

        &:before {
          content: attr(data-on);
          top: 0.8rem;
          right: 2rem;
          opacity: 0.6;
        }

        &:after {
          content: attr(data-off);
          width: 7rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          background: linear-gradient(
            180deg,
            rgba(1, 14, 19, 0.85) 0%,
            rgba(43, 70, 82, 0.85) 100%
          );
          backdrop-filter: blur(17px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: inherit;
          color: var(--secondaryColor);
          border-bottom: 1px solid var(--secondaryColor);
        }
      }

      input[type="checkbox"] {
        cursor: pointer;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;

        &:checked + .switch-inner {
          &::after {
            content: attr(data-on);
            left: 7rem;
            background: linear-gradient(
              180deg,
              rgba(1, 14, 19, 0.85) 0%,
              rgba(43, 70, 82, 0.85) 100%
            );
            backdrop-filter: blur(17px);
            border-radius: inherit;
          }

          &::before {
            content: attr(data-off);
            left: 2.5rem;
            top: 0.8rem;
          }
        }
      }
    }
  }
}

.dnone {
  display: none;
  // z-index: -1;
  // opacity: 0;
  // visibility: hidden;
}

.canvasHolderDesktop {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvasHolder {
  position: relative;
  max-width: 100%;
  width: 100vw;
  // height: calc(80vw * 0.777);
  height: calc(35vh * 0.777);
}

.canvasHolderFullScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--app-height);
  z-index: 1;
}

.canvasHolder::after {
  content: " ";
  width: 100%;
  height: 2rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgb(28, 111, 148);
  background: linear-gradient(
    180deg,
    rgba(28, 111, 148, 0) 0%,
    rgb(37 82 105) 52%,
    rgb(37 81 102) 100%
  );
}

.parentContainer {
  background: var(--primaryColor);
  // backdrop-filter: blur(34px);
  /* Note: backdrop-filter has minimal browser support */
  transform: scale(0.8);
  border-radius: 25px;
  padding: 2rem;
  height: 57rem;
  min-height: 57rem;

  .rights {
    opacity: 0.5;
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }

  .bottomBtnTextFix {
    position: absolute;
    bottom: 0;
  }
}

@media screen and (min-width: 800px) {
  .container {
    width: 60vw;
  }
}

@media screen and (min-width: 992px) {
  .dnone {
    // display: none;
    // z-index: -1;
    // opacity: 0;
    display: block;
    visibility: hidden;
  }

  .container {
    width: unset;
  }

  .parentContainer {
    position: absolute;
    right: 4%;
    width: 24rem;
    height: 42rem;
    min-height: 42rem;
    transform: scale(1);
  }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  // .canvasHolder {
  //   display: none;
  // }
  .parentContainer2 {
    position: absolute;
    right: 0%;
    width: 24rem;
    height: 40rem;
    transform: scale(0.5);
    .container {
      width: 100%;
    }
  }
}
@media screen and (max-width: 389px) {
  .parentContainer2 {
    height: 135vh;
    transform: scale(0.6) !important;

    .container {
      width: 100vw;
    }
  }
}
